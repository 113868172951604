var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c(
        "nuxt-link",
        {
          staticClass: "d-flex align-center",
          attrs: { to: _vm.localePath("/") },
        },
        [
          _c(
            "v-icon",
            { staticClass: "mr-2 back-link", attrs: { small: "" } },
            [_vm._v("mdi-arrow-left")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "back-link" }, [
            _vm._v(_vm._s(_vm.$t("back"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "h_title_2 mt-5 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("loginTitle"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-dark-grey mb-5" }, [
        _vm._v(_vm._s(_vm.$t("loginSubText"))),
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-0" },
            [
              _c(
                "v-form",
                {
                  ref: "loginForm",
                  attrs: { onSubmit: "return false;" },
                  on: { submit: _vm.login },
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("emailAddress")))]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mt-1 mb-5",
                    attrs: {
                      outlined: "",
                      placeholder: _vm.$t("enterYourEmail"),
                      "hide-details": "auto",
                      rules: _vm.emailRule,
                    },
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v(_vm._s(_vm.$t("password")))]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      outlined: "",
                      placeholder: _vm.$t("enterYourPass"),
                      "hide-details": "auto",
                      rules: _vm.passwordRule,
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                    },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasError
                    ? _c("alert-error", {
                        staticClass: "mt-5",
                        attrs: { "error-message": _vm.errorMessage },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center mb-5 justify-end mt-4 mb-10",
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-0 mr-auto pt-0 remember-me",
                        attrs: {
                          label: _vm.$t("rememberMe"),
                          "hide-details": "auto",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "nuxt-link",
                        {
                          attrs: {
                            to: _vm.localePath("/account/forgot-password"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("forgotPassword")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        block: "",
                        rounded: "",
                        large: "",
                        color: "btn-brand",
                        type: "submit",
                        loading: _vm.loading,
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("signIn")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt-7 text-center mb-0 font-weight-medium" },
                [
                  _vm._v(_vm._s(_vm.$t("dontHaveAnAccount")) + " "),
                  _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/account/register") } },
                    [_vm._v(_vm._s(_vm.$t("signUp")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }