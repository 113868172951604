
import { Component, Vue } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({
  head(this: Login): object {
    return {
      title: this.$t('titleLogin'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('loginPage')
        }
      ]
    }
  },
  layout: 'login-signup',
  auth: 'guest'
})
export default class Login extends Vue {
  showPassword: boolean = false;
  loading: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  user: any = {};
  emailRule: any = [];
  passwordRule: any = [];

  async mounted() {
    const emailRequired = this.$t('emailRequired') as any;
    const passRequired = this.$t('passRequired') as any;

    this.emailRule = [ requiredRule(emailRequired) ];
    this.passwordRule = [ requiredRule(passRequired) ];

    if(this.$auth.loggedIn) {
      await this.$auth.logout();
    }
  }

  async login() {
    this.hasError = false;
    const form = this.$refs.loginForm as any;
    if(form.validate()) {
      this.loading = true;
      await this.$auth.loginWith('local', {
        data: {
          email: this.user.email,
          password: this.user.password
        }
      }).then((res) => {
        this.$router.push(this.localePath('/dashboard/home'));
      }).catch((error) => {
        if (error.response) {
          this.hasError = true;
          this.errorMessage = getErrorMessage(error, this.$t('errorLoginIn'));
        }
      })
      this.loading = false;
    }
  }
}
